import { IsNotEmpty, Length } from 'class-validator';
import { IfDefineThanLength } from '@/utils/custom-validators';
import { VALID_ERRORS } from '@/constants/errors';
import { Entity } from './Entity';

export enum STATUS {
  CITY_OF_ORIGIN,
  IN_TRANSIT,
  CITY_OF_DESTINATION,
}

export enum KPP {
  MANUAL = 'Manual',
  AUTOMATIC = 'Automatic',
  VARIATOR = 'Variator',
  ROBOT = 'Robot',
}

export enum DRIVE {
  FRONT = 'Front',
  FULL = 'Full',
  REAR = 'Rear',
}

export enum WHEEL {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export interface Donors {
  data?: Donor[] | null;
  page?: number;
  pages?: number;
  rows?: number;
}

export interface Image {
  guid: string;
  url: string;
  url_: string;
}

interface DonorInterface {
  id?: number;
  number?: number;
  name?: string;
  lotId?: number;
  lot?: { id: number; name: string };
  kpp?: KPP;
  wheel?: WHEEL;
  status?: STATUS;
  drive?: DRIVE;
  marka?: string;
  model?: string;
  bodyNumber?: string;
  engine?: string;
  year?: number;
  kppModel?: string;
  complectation?: string;
  vin?: string;
  description?: string;
  note?: string;
  mileage?: string;
  color?: string;
  price?: number;
  images?: Image[];
  imagesGuid?: string[];
}

export class Donor extends Entity<Donor> implements DonorInterface {
  id?: number;
  number?: number;
  name?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  lotId?: number;
  lot?: { id: number; name: string };
  kpp?: KPP;
  wheel?: WHEEL;
  status?: STATUS;
  drive?: DRIVE;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(0, 100, { message: VALID_ERRORS.length(0, 100) })
  marka?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(0, 100, { message: VALID_ERRORS.length(0, 100) })
  model?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(0, 100, { message: VALID_ERRORS.length(0, 100) })
  bodyNumber?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(0, 100, { message: VALID_ERRORS.length(0, 100) })
  engine?: string;
  year?: number;
  kppModel?: string;
  complectation?: string;
  @IfDefineThanLength(15, 17, { message: VALID_ERRORS.length(15, 17) })
  vin?: string;
  description?: string;
  note?: string;
  mileage?: string;
  color?: string;
  price?: number;
  images?: Image[];
  imagesGuid?: string[];

  constructor(data: DonorInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: DonorInterface) {
    this.marka = data.marka || undefined;
    this.model = data.model || undefined;
    this.lotId = (data.lot && data.lot.id) || data.lotId || undefined;
    this.lot = data.lot || undefined;
    this.drive = data.drive || undefined;
    this.vin = data.vin || undefined;
    this.wheel = data.wheel || undefined;
    this.id = data.id || undefined;
    this.bodyNumber = data.bodyNumber || undefined;
    this.engine = data.engine || undefined;
    this.year = data.year || undefined;
    this.kpp = data.kpp || undefined;
    this.price = data.price || undefined;
    this.images = data.images || undefined;
    this.imagesGuid = data.imagesGuid || undefined;
  }
}
