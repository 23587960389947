import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      options: _ctx.donors.data,
      modelValue: _ctx.inputVal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputVal = $event)),
      optionLabel: option => _ctx.displayName(option),
      optionValue: option => option.id,
      showClear: true,
      class: [{ 'p-invalid': _ctx.invalid }],
      placeholder: "Выберите Донора"
    }, {
      option: _withCtx((slotProps) => [
        _createVNode("div", null, [
          _createVNode("div", null, _toDisplayString(_ctx.displayName(slotProps.option)), 1)
        ])
      ]),
      _: 1
    }, 8, ["options", "modelValue", "optionLabel", "optionValue", "class"])
  ]))
}