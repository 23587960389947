import { FormFields } from '@/interfaces/Input';
import { Condition, Pitch, Yaw, Roll } from '@/classes/models/Part';

const fields: FormFields = {
  name: {
    title: 'Наименование',
    type: 'input',
    slot: 'name',
  },
  nomenclatureId: {
    title: 'Номенклатура',
    type: 'nomenclature',
    slot: 'nomenclature',
  },
  donorId: {
    title: 'Донор',
    type: 'donor',
    slot: 'donorSelector',
  },
  condition: {
    title: 'Состояние',
    type: 'select',
    options: [
      { title: 'Новая', value: Condition.NEW },
      { title: 'Б/у', value: Condition.USED },
      { title: 'Контрактная', value: Condition.CONTRACT },
    ],
    slot: 'condition',
  },
  marka: {
    title: 'Марка',
    type: 'input',
    slot: 'donor',
  },
  model: {
    title: 'Модель',
    type: 'input',
    slot: 'donor',
  },
  bodyNumber: {
    title: 'Номер кузова',
    type: 'input',
    slot: 'donor',
  },
  engine: {
    title: 'Номер двигателя',
    type: 'input',
    slot: 'donor',
  },
  year: {
    title: 'Год',
    type: 'input',
    slot: 'donor',
  },
  price: {
    title: 'Цена',
    type: 'input',
    slot: 'price',
  },
  manufacturer: {
    title: 'Производитель',
    type: 'input',
    slot: 'manufacturer',
  },
  oemNumber: {
    title: 'OEM номер',
    type: 'input',
    slot: 'oemNumber',
  },
  number: {
    title: 'Номер',
    type: 'input',
    slot: 'manufacturerNumber',
  },
  color: {
    title: 'Цвет',
    type: 'input',
    slot: 'color',
  },
  leftRight: {
    title: 'Левый/Правый',
    type: 'select',
    options: [
      { title: 'Левый', value: Pitch.LEFT },
      { title: 'Правый', value: Pitch.RIGHT },
    ],
    slot: 'yaw',
  },
  topBottom: {
    title: 'Верхний/Нижний',
    type: 'select',
    options: [
      { title: 'Верхний', value: Yaw.TOP },
      { title: 'Нижний', value: Yaw.BOTTOM },
    ],
    slot: 'pitch',
  },
  frontBack: {
    title: 'Передний/Задний',
    type: 'select',
    options: [
      { title: 'Передний', value: Roll.FRONT },
      { title: 'Задний', value: Roll.BACK },
    ],
    slot: 'roll',
  },
  discount: {
    title: 'Скидка',
    type: 'input',
  },
  images: {
    title: 'Изображения',
    type: 'photos',
    slot: 'photos',
  },
  comment: {
    title: 'Комментарий',
    type: 'textarea',
    slot: 'comments',
  },
  description: {
    title: 'Заметка',
    type: 'textarea',
    slot: 'comments',
  },
};

export default fields;
