
import { computed, defineComponent, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';

import { Part } from '@/classes/models/Part';
import locals from '@/constants/localeDew';

import ProductCatalog from '@/components/Products/ProductCatalog.vue';
import { PartApi } from '@/classes/api/PartApi';

const SEARCH_INTERVAL = 2000;

export default defineComponent({
  name: 'PartsSelector',
  emits: ['update:modelValue', 'editComplete'],
  components: {
    ProductCatalog,
    Dialog,
    Button,
    Dropdown,
    InputText,
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.ProductForm = require('../Products/ProductForm.vue').default;
    }
  },
  props: {
    modelValue: Object as PropType<Part>,
    addFunction: {
      type: Boolean,
      default: true,
    },
    removeFunction: {
      type: Boolean,
      default: true,
    },
    selectFunction: {
      type: Boolean,
      default: true,
    },
    postedOnlyOnMounted: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, ctx) {
    const dialog = ref(false);
    const fetchedParts = ref<Part[]>([]);
    const searchValue = ref('');
    const dialogNew = ref(false);
    const dialogDropbox = ref(false);
    const id = ref<number>();
    const editMode = ref(false);
    const update = ref<Function>();

    const api = new PartApi();
    let timer;

    // eslint-disable-next-line
    const openDialog = (event: any) => {
      dialogNew.value = true;
      id.value = event.id;
      editMode.value = event.editMode;
      update.value = event.update;
    };

    // eslint-disable-next-line
    const openDroplistDialog = (event: any) => {
      dialogDropbox.value = true;
      if (event.id) {
        id.value = event.id;
      }
      editMode.value = event.editMode;
    };

    const inputValue = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const updatePartFromDroplist = (part: Part) => {
      fetchedParts.value = [part];
      inputValue.value = part;
      ctx.emit('editComplete');
    }

    const setPartsToDroplist = (partsFromList: Part[]) => {
      fetchedParts.value = partsFromList;
      inputValue.value = partsFromList[0];
      ctx.emit('editComplete');
      dialog.value = false;
    };

    const getPartName = (item: Part): string => {
      if (item.nomenclature) {
        return `
          ${item.nomenclature.name}
          ${item.marka}
          ${item.model}
          ${item.leftRight ? locals[item.leftRight] : ''}
          ${item.topBottom ? locals[item.topBottom] : ''}
          ${item.frontBack ? locals[item.frontBack] : ''}
        `;
      } else if (item.name) {
        return item.name;
      }
      return '';
    };

    const searchHandler = () => {
      if (!timer) {
        timer = setTimeout(async () => {
          try {
            fetchedParts.value = await api.fetchFiltered(searchValue.value);
          } catch (error) {
            fetchedParts.value = [];
            console.log(error);
          }
          clearTimeout(timer);
          timer = null;
        }, SEARCH_INTERVAL);
      }
    };

    const partSelected = () => {
      ctx.emit('editComplete');
    };

    return {
      dialog,
      searchValue,
      inputValue,
      parts: fetchedParts,
      dialogNew,
      dialogDropbox,
      id,
      editMode,
      update,
      openDialog,
      openDroplistDialog,
      setPartsToDroplist,
      getPartName,
      searchHandler,
      partSelected,
      updatePartFromDroplist,
    };
  },
});
