import { Api } from './Api';
import { Location } from '../models/Location';
import CustomError from '../models/CustomError';

export class LocationApi extends Api {
  async fetchAll(): Promise<Location[]> {
    let locations: Location[] = [];
    try {
      const result = await this.fetchAllProtected<Location[]>('locations');
      if (result && result.length > 0) {
        locations = Location.createFromArray(Location, result);
      }
      return [...locations];
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Location> {
    try {
      const result = await this.fetchByIdProtected<Location>('locations', id);
      if (result) {
        return Location.createFromObject(Location, result);
      }
      return new Location({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Location): Promise<Location> {
    try {
      const result = await this.sendProtected<Location>('locations', data);
      return Location.createFromObject(Location, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Location): Promise<Location> {
    try {
      const result = await this.updateProtected('locations', data);
      return Location.createFromObject(Location, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('locations', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
