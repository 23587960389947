import { Api } from './Api';
import { Donor, Donors } from '../models/Donor';
import CustomError from '../models/CustomError';

export class DonorApi extends Api {
  async fetchAll(page: number, pageSize: number): Promise<Donors> {
    let donors: Donor[] = [];
    try {
      const result = await this.fetchAllProtected<Donors>('catalog/donor', {
        params: { Page: page, PageSize: pageSize },
      });
      if (result && result.data && result.data.length > 0) {
        donors = Donor.createFromArray(Donor, result.data);
      }
      return {
        ...result,
        data: donors,
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Donor> {
    try {
      const result = await this.fetchByIdProtected<Donor>('catalog/donor', id);
      if (result) {
        return Donor.createFromObject(Donor, result);
      }
      return new Donor({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Donor): Promise<Donor> {
    try {
      const result = await this.sendProtected<Donor>('catalog/donor', data);
      return Donor.createFromObject(Donor, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Donor): Promise<Donor> {
    try {
      const result = await this.updateProtected('catalog/donor', data);
      return Donor.createFromObject(Donor, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('catalog/donor', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
