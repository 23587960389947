// eslint-disable-next-line
const isObjectsMatches = (first: any, second: any): boolean => {
  const theSame = Object.keys(first).every(key => {
    return first[key] === second[key];
  });
  if (!theSame) {
    return false;
  }
  if (Object.keys(first).length !== Object.keys(second).length) {
    return false;
  }
  return true;
}

export default isObjectsMatches;