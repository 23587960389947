
import {ref, onMounted, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dropdown from "primevue/dropdown";

import { DonorApi } from '@/classes/api/DonorApi';
import { Donors, Donor } from '@/classes/models/Donor';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  components: {
    Dropdown
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Number,
    invalid: Boolean,
    id: String,
  },
  setup(props, ctx) {
    const donors = ref<Donors>({data: []});
    const donorApi = new DonorApi();
    const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      }
    });

    const update = async () =>
    {
      try {
        donors.value = await donorApi.fetchAll(1, 100);
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
    }

    const displayName = (donor: Donor): string => {
      let name = '';
      if (donor.id) {
        name = donor.id.toString() + ':';
      }
      if (donor.marka) {
        name += ` ${donor.marka}`;
      }
      if (donor.model) {
        name += ` ${donor.model}`;
      }
      if (donor.bodyNumber) {
        name += ` ${donor.bodyNumber}`;
      }
      if (donor.engine) {
        name += ` ${donor.engine}`;
      }
      if (donor.year) {
        name += ` ${donor.year}`;
      }

      return name;
    }

    onMounted(() =>
    {
      update();
    });

    return {
      inputVal,
      donors,
      displayName
    }
  }
});
