
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import DataTable from '@/components/UI/DataTable/DataTable.vue';
// import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ContextMenu from 'primevue/contextmenu';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';

import { Nomenclature } from '@/classes/models/Nomenclature';
import { PartApi } from '@/classes/api/PartApi';
import { Part } from '@/classes/models/Part';
import { Filters } from '@/interfaces/Filters';
import locals from '@/constants/localeDew';
import CustomError from '@/classes/models/CustomError';
import NomenclatureSelector from '@/components/UI/Selectors/NomenclatureSelector.vue';
import LotSelector from '@/components/UI/Selectors/LotSelector.vue';
import { dictionariesModule } from '@/store/modules/dictionaries';

import ProductMultiForm from '@/components/Products/ProductMultiForm.vue';
import { Lot } from '@/classes/models/Lot';
import isObjectsMatches from '@/utils/isObjectsMatches';
import { PhotoInterface } from '@/interfaces/Photo';

export default defineComponent({
  name: 'ProductCatalog',
  components: {
    NomenclatureSelector,
    LotSelector,
    DataTable,
    ProductMultiForm,
    SelectButton,
    Column,
    Button,
    ContextMenu,
    InputText,
    Dropdown,
  },
  emit: ['selected', 'new'],
  props: {
    multi: {
      type: Boolean,
      default: true,
    },
    addFunction: {
      type: Boolean,
      default: false,
    },
    editFunction: {
      type: Boolean,
      default: true,
    },
    removeFunction: {
      type: Boolean,
      default: false,
    },
    selectFunction: {
      type: Boolean,
      default: false,
    },
    postedOnlyOnMounted: {
      type: Boolean,
      default: true,
    },
    dictionary: {
      type: Boolean,
      default: false,
    },
  },

  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.ProductForm = require('../../components/Products/ProductForm').default;
    }
  },

  setup(props, ctx) {
    const loading = ref(false);
    const newPartForm = ref(false);
    const pages = ref(0);
    const page = ref(0);
    const size = ref(40);
    const totalRecords = ref(0);
    const loadedRecords = ref(0);
    const item = reactive<Part>(new Part({}));
    const oldItem = reactive<Part>(new Part({}));
    const products = ref<Part[]>([]);
    const selected = ref<Part[]>([]);
    const selectedProduct = ref<Part>(new Part({}));
    const editMode = ref(false);
    const nameFilterId = ref(0);
    const lotFilterId = ref(0);
    const multiFromVisible = ref(false);
    const totalPrice = ref(0);
    const marks = ref<{ label: string }[]>([]);
    const models = ref<{ label: string }[]>([]);
    // eslint-disable-next-line
    const ctxMenu = ref<any>(null);
    // eslint-disable-next-line
    const dt = ref<any>(null);
    const filters = reactive<Filters>({});
    const displayFullImages = ref(false);
    // eslint-disable-next-line
    const blockPosition = ref();
    const fullImagesMap = ref<PhotoInterface[]>([]);

    const postedOnly = ref(true);
    const showPosted = [
      { name: 'В наличии', value: true },
      { name: 'Проданы', value: false },
      { name: 'Все', value: null },
    ];
    const leftRight = [
      { title: 'Лев.', value: 'Left' },
      { title: 'Прав.', value: 'Right' },
    ];
    const topBottom = [
      { title: 'Вер.', value: 'Top' },
      { title: 'Низ', value: 'Bottom' },
    ];
    const frontBack = [
      { title: 'Пер.', value: 'Front' },
      { title: 'Зад', value: 'Back' },
    ];

    const headerStyle = 'width: 15rem';
    const confirm = useConfirm();
    const toast = useToast();
    const router = useRouter();
    const api = new PartApi();
    const nomenclature = ref<Nomenclature[]>([]);
    const lots = ref<Lot[]>([]);

    const exportCSV = async () => {
      // dt.value.exportCSV();
      loading.value = true;
      try {
        await api.downloadCSV({
          ...filters,
          InStock: postedOnly.value,
        });
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    const select = () => {
      if (props.selectFunction) {
        if (props.multi) {
          ctx.emit('selected', selected.value);
        } else {
          ctx.emit('selected', [selected.value]);
        }
      }
    };

    const getImageUrl = (name: string): string => {
      return `${process.env.VUE_APP_IMAGE_URL}${name}`;
    };

    const recalculateTotalPrice = () => {
      totalPrice.value = 0;
      products.value.forEach(item => {
        if (item.price) {
          totalPrice.value += item.price;
        }
      });
    };

    const formatter = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',

      //minimumFractionDigits: 0, // (2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (2500.99 as $2,501)
    });

    const format = (text: number): string => {
      return formatter.format(text);
    };

    const update = async () => {
      if (nameFilterId.value) {
        nomenclature.value.every(item => {
          if (item.id === nameFilterId.value) {
            if (item.name) {
              filters.Name = item.name;
            }
            return false;
          }
          return true;
        });
      }

      if (lotFilterId.value) {
        lots.value.every(item => {
          if (item.id === lotFilterId.value) {
            filters.LotId = item.id;
            return false;
          }
          return true;
        });
      }

      page.value = 0;
      loading.value = true;
      try {
        const result = await api.fetchAll(page.value + 1, size.value, {
          ...filters,
          InStock: postedOnly.value,
        });
        products.value = result.data;
        totalRecords.value = result.rows;
        loadedRecords.value = result.data.length;
        pages.value = result.pages;
        recalculateTotalPrice();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    const markUpdated = async () => {
      if (filters.Marka) {
        await dictionariesModule.actions.loadModels({ Marka: filters.Marka });
        models.value = dictionariesModule.state.models.map(label => ({
          label,
        }));
        await update();
      }
    };

    const postedOnlySwitchHandler = () => {
      postedOnly.value = !postedOnly.value;
      update();
    };

    const filtersClear = () => {
      for (const param in filters) {
        delete filters[param];
      }
      nameFilterId.value = 0;
      lotFilterId.value = 0;
      update();
    };

    const filterClear = (filter: string) => {
      for (const param in filters) {
        if (filter === param) {
          delete filters[param];
        }
      }
      if (filter === 'name') {
        nameFilterId.value = 0;
        delete filters.Name;
      }
      if (filter === 'lot') {
        lotFilterId.value = 0;
        delete filters.LotId;
      }
      update();
    };

    // eslint-disable-next-line
    const updatePage = (event: any) => {
      page.value = event.page;
      update();
    };

    onMounted(async () => {
      postedOnly.value = props.postedOnlyOnMounted;
      await dictionariesModule.actions.loadNomenclatures();
      await dictionariesModule.actions.loadMarks();
      await dictionariesModule.actions.loadLots();
      nomenclature.value = dictionariesModule.state.nomenclature;
      marks.value = dictionariesModule.state.marks.map(label => ({ label }));
      lots.value = dictionariesModule.state.lots;
      await update();
    });

    // eslint-disable-next-line
    const onRowContextMenu = (event: any) => {
      ctxMenu.value.show(event.originalEvent);
    };

    const openNew = () => {
      ctx.emit('new', {
        editMode: false,
        update,
      });
    };

    const editProduct = (selected: Part) => {
      ctx.emit('new', {
        editMode: true,
        id: selected.id,
        update,
      });
    };

    const openEdit = () => {
      if (selected.value.length === 1) {
        editProduct(selected.value[0]);
      } else {
        multiFromVisible.value = true;
      }
    };

    const openProduct = (event: { data: Part }) => {
      if (props.dictionary) {
        router.push('/dictionaries/products/' + event.data.id);
      } else {
        router.push('/products/' + event.data.id);
      }
    };

    const deleteHandler = () => {
      confirm.require({
        message: 'Действительно хотите удалить выбранное?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            for (const item of selected.value) {
              if (item.id) {
                await api.remove(item.id);
              }
            }
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Удалено',
              life: 3000,
            });
            await update();
          } catch (error) {
            if (error instanceof CustomError) {
              error.show('error', 5000, toast);
            } else {
              console.log(error);
            }
          }
        },
      });
    };

    const loadMore = async () => {
      if (page.value >= pages.value) {
        return;
      }

      loading.value = true;
      try {
        page.value += 1;
        const result = await api.fetchAll(page.value + 1, size.value, {
          ...filters,
          InStock: postedOnly.value,
        });
        products.value = [...products.value, ...result.data];
        loadedRecords.value += result.data.length;
        recalculateTotalPrice();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    // eslint-disable-next-line
    const cellEditInit = (event: any) => {
      oldItem.replaceBy(new Part(event.data));
    };

    // eslint-disable-next-line
    const cellEditComplete = async (event: any) => {
      const lot = new Part(event.data);
      if (!isObjectsMatches(lot, oldItem)) {
        loading.value = true;
        try {
          await api.update(lot);
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
    };

    const contexMenu = [
      {
        label: 'Открыть',
        icon: 'pi pi-fw pi-search',
        command: () => {
          openProduct({ data: selectedProduct.value });
        },
      },
      {
        label: 'Редактировать',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          editProduct(selectedProduct.value);
        },
      },
    ];

    const showFullImages = (
      event: TouchEvent | MouseEvent,
      images: PhotoInterface[]
    ) => {
      displayFullImages.value = true;
      fullImagesMap.value = images;
      let posX = 0;
      let posY = 0;
      // TODO: Работает не стабильно
      if (event instanceof MouseEvent) {
        posX = event.clientX;
        posY = event.clientY;
      } else if (event instanceof TouchEvent) {
        posX = event.touches[0].pageX;
        posY = event.touches[0].pageY;
      }
      const x = (posX + 10).toString() + 'px';
      if (posY > window.innerHeight / 2) {
        const y = (posY + 10).toString() + 'px';
        blockPosition.value = {
          left: x,
          top: -y,
        };
      } else {
        const y = (posY + 10).toString() + 'px';
        blockPosition.value = {
          left: x,
          top: y,
        };
      }
    };

    const hideFullImages = () => {
      displayFullImages.value = false;
    };

    return {
      dt,
      products,
      selected,
      selectedProduct,
      totalRecords,
      loadedRecords,
      loading,
      headerStyle,
      newPartForm,
      item,
      size,
      editMode,
      contexMenu,
      ctxMenu,
      filters,
      postedOnly,
      nameFilterId,
      lotFilterId,
      multiFromVisible,
      showPosted,
      totalPrice,
      leftRight,
      topBottom,
      frontBack,
      marks,
      models,
      displayFullImages,
      blockPosition,
      fullImagesMap,
      locals,
      showFullImages,
      hideFullImages,
      deleteHandler,
      select,
      onRowContextMenu,
      updatePage,
      openNew,
      getImageUrl,
      update,
      filterClear,
      filtersClear,
      postedOnlySwitchHandler,
      loadMore,
      openEdit,
      openProduct,
      exportCSV,
      format,
      markUpdated,
      cellEditInit,
      cellEditComplete,
    };
  },
});
