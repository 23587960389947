import { Api } from './Api';
import { Part, Parts } from '../models/Part';
import { Filters } from '@/interfaces/Filters';
import CustomError from '../models/CustomError';
import axios from '@/axios-zaconte';

interface UpdateMany {
  ids: number[];
  donorId?: number;
  price?: number;
  discount?: number | null;
  priceAdd?: number | null;
}

export class PartApi extends Api {
  async fetchAll(
    page: number,
    pageSize: number,
    filters: Filters
  ): Promise<Parts> {
    let parts: Part[] = [];
    try {
      const result = await this.fetchAllProtected<Parts>('catalog/part', {
        params: { Page: page, PageSize: pageSize, ...filters },
      });
      if (result && result.data && result.data.length > 0) {
        parts = Part.createFromArray(Part, result.data);
      }
      return {
        ...result,
        data: parts,
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async downloadCSV(filters: Filters): Promise<void> {
    try {
      const response = await axios.get('catalog/part/download', {
        responseType: 'blob',
        params: { ...filters },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'base.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchFiltered(query: string): Promise<Part[]> {
    let parts: Part[] = [];
    try {
      const result = await this.fetchFilteredProtected<Part[]>(
        'catalog/part/quick-search',
        {
          params: { Query: query },
        }
      );
      if (result && result.length > 0) {
        parts = Part.createFromArray(Part, result);
      }
      return parts;
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Part> {
    try {
      const result = await this.fetchByIdProtected<Part>('catalog/part', id);
      if (result) {
        return Part.createFromObject(Part, result);
      }
      return new Part({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Part): Promise<Part> {
    try {
      const result = await this.sendProtected<Part>('catalog/part', data);
      return Part.createFromObject(Part, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Part): Promise<Part> {
    try {
      const result = await this.updateProtected('catalog/part', data);
      return Part.createFromObject(Part, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async updateMany(data: UpdateMany): Promise<void> {
    try {
      await this.updateManyProtected('catalog/part/mass', data);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('catalog/part', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
