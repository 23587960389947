
import { defineComponent } from 'vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';

import { authModule } from '@/store/modules/auth';
import CustomError from '@/classes/models/CustomError';

import Captcha from '../UI/Captcha.vue';

export default defineComponent({
  components: {
    Captcha,
    Button,
    Card,
    InputText,
  },
  data: () => ({
    email: '',
    captchaGuid: '',
    captchaToken: '',
    loading: false,
  }),
  methods: {
    async resetHandler () {
      try {
        await authModule.actions.resetRequest({
          email: this.email,
          guid: this.captchaGuid,
          token: this.captchaToken,
        });
        this.$toast.add({
          severity: 'success',
          summary: 'Успешно',
          detail: 'На Вашу почту направлены инструкции для смены пароля',
          life: 5000,
        });
        await this.$router.push('/');
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, this.$toast);
        } else {
          console.log(error);
        }
      }
    },
  },
});
