
import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from 'vue';

import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
// import Button from 'primevue/button';

import { Location } from '@/classes/models/Location';
import { LocationApi } from '@/classes/api/LocationApi';

// import LocationForm from '../../Location/LocationForm.vue';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    // LocationForm,
    Dropdown,
    // Button,
  },
  props: {
    modelValue: Number,
    invalid: Boolean,
    id: String,
  },

  setup(props, ctx) {
    const locationFormDialog = ref<boolean>(false);
    const locations = ref<Location[]>([]);

    const locationApi = new LocationApi();
    const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const update = async () => {
      try {
        locations.value = await locationApi.fetchAll();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
    };

    onMounted(() => {
      update();
    });

    const addButtonHandler = () => {
      locationFormDialog.value = true;
    };

    return {
      locations,
      inputVal,
      locationFormDialog,
      addButtonHandler,
      update,
    };
  },
});
