const locals = {
  Left: 'Лев.',
  Right: 'Прав.',
  Front: 'Пер.',
  Back: 'Зад',
  Top: 'Верх',
  Bottom: 'Низ',
};

export default locals;
