
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useToast } from 'primevue/usetoast';
import Card from 'primevue/card';
import Button from 'primevue/button';

import ResetPassword from '@/classes/models/ResetPassword';
import { FormHandler } from '@/classes/forms/FormHandler';
import ResetFormFields from '@/constants/FormFields/reset';
import { SERVER_ERRORS } from '@/constants/errors';
import { authModule } from '@/store/modules/auth';

import DreenInput from '@/components/UI/Input.vue';

export default {
  components: {
    Card,
    Button,
    DreenInput,
  },
  setup() {
    const loading = ref(false);
    const item = reactive(new ResetPassword('', '', ''));
    const fields = reactive({ ...ResetFormFields });
    const formHandler = new FormHandler(fields, item);

    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      if (route.query.token) {
        item.token = route.query.token.toString();
      }
    });

    async function saveHandler() {
      loading.value = true;
      const valid = await formHandler.checkValidations();
      if (valid) {
        try {
          await authModule.actions.reset(item);
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail: 'Пароль успешно изменен',
            life: 5000,
          });
          await router.push('/login');
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: 'Ошибка регистрации',
            detail: SERVER_ERRORS[error.message || '999'],
            life: 3000,
          });
        }
      }
      loading.value = false;
    }

    return {
      fields,
      item,
      formHandler,
      loading,
      saveHandler,
    };
  },
};
