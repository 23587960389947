import { IsNotEmpty, Length } from 'class-validator';

import { IfDefineThanLength } from '@/utils/custom-validators';
import { Entity } from './Entity';
import { VALID_ERRORS } from '@/constants/errors';

interface LocationInterface {
  id?: number;
  name?: string;
  description?: string;
}

export class Location extends Entity<Location> implements LocationInterface {
  id?: number;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 50, { message: VALID_ERRORS.length(3, 50) })
  name?: string;
  @IfDefineThanLength(0, 250, { message: VALID_ERRORS.length(0, 250) })
  description?: string;

  constructor(data: LocationInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: LocationInterface) {
    this.name = data.name || undefined;
    this.description = data.description || undefined;
    this.id = data.id || undefined;
  }
}
