import { IsNotEmpty, Length } from 'class-validator';

import { IsEqualTo } from '@/utils/custom-validators';
import { VALID_ERRORS } from '@/constants/errors';

interface FormDataInterface {
  token: string;
  password: string;
  password2: string;
}

class FormData implements FormDataInterface {
  @IsNotEmpty({ message: VALID_ERRORS.required })
  token: string;
  @Length(5, 50, { message: VALID_ERRORS.length(5, 50) })
  password: string;
  @IsEqualTo('password', { message: VALID_ERRORS.passwordMatches })
  password2: string;

  constructor(token: string, password: string, password2: string) {
    this.token = token;
    this.password = password;
    this.password2 = password2;
  }

  replaceBy(data: FormDataInterface) {
    this.token = data.token;
    this.password = data.password;
    this.password2 = data.password2;
  }
}

export default FormData;
