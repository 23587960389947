import { FormFields, FormStructure } from '@/interfaces/Input';
import { validate } from 'class-validator';

export class FormHandler {
  fields: FormFields;
  item: object;

  constructor(fields: FormFields, item: object) {
    this.fields = fields;
    this.item = item;
  }

  setObjectToValidate(item: object) {
    this.item = item;
  }

  async checkValidation(key: string) {
    this.fields[key].invalid = false;
    const errors = await validate(this.item);
    if (errors.length > 0) {
      const error = errors.filter(error => error.property === key);
      if (error.length > 0) {
        this.fields[key].invalid = true;
        this.fields[key].validationErrors =
          error[0].constraints &&
          Object.values(error[0].constraints).map(error => error);
      } else {
        this.resetFieldValid(key);
      }
    } else {
      this.resetFieldValid(key);
    }
  }

  resetFieldValid(key: string) {
    this.fields[key].invalid = false;
    this.fields[key].validationErrors = [];
  }

  async checkValidations() {
    this.resetValidations();
    const errors = await validate(this.item);
    if (errors.length > 0) {
      errors.forEach(error => {
        this.fields[error.property].invalid = true;
        this.fields[error.property].validationErrors =
          error.constraints &&
          Object.values(error.constraints).map(error => error);
      });
      return false;
    } else {
      this.resetValidations();
      return true;
    }
  }

  resetValidations() {
    Object.values(this.fields).forEach(field => {
      field.invalid = false;
      field.validationErrors = [];
    });
  }

  createFormStructure(): FormStructure {
    const result: FormStructure = {};
    Object.keys(this.fields).forEach(key => {
      if (this.fields[key]) {
        /* eslint-disable */
        const slotName = this.fields[key].slot
          ? this.fields[key].slot!
          : 'default';
        if (!result[slotName]) {
          result[slotName] = [];
        }
        result[slotName].push(key);
      }
    });
    return result;
  }
}
