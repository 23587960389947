
import { ref, onMounted, defineComponent, computed } from 'vue';

// import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
// import Button from 'primevue/button';

import { Nomenclature } from '@/classes/models/Nomenclature';
import { dictionariesModule } from '@/store/modules/dictionaries';

// import NomenclatureForm from '../../Nomenclature/NomenclatureForm.vue';
// import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['update:modelValue', 'update:namedValue', 'filter'],
  components: {
    // 'nomenclature-form': NomenclatureForm,
    Dropdown,
    // Button
  },
  props: {
    modelValue: Number,
    invalid: Boolean,
    id: String,
  },
  setup(props, ctx) {
    const nomenclatureFormDialog = ref(false);
    const nomenclature = ref<Nomenclature[]>([]);
    // const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const filter = () => {
      ctx.emit('filter');
    };

    const update = () => {
      nomenclature.value = dictionariesModule.state.nomenclature;
    };

    onMounted(() => {
      update();
      dictionariesModule.watch(
        (state) => state.nomenclature,
        value => {
          nomenclature.value = value;
        },
      )
    });

    const addButtonHandler = () => {
      nomenclatureFormDialog.value = true;
    };

    return {
      inputVal,
      nomenclature,
      nomenclatureFormDialog,
      // update,
      addButtonHandler,
      filter,
    };
  },
});
