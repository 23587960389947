
import { defineComponent, computed } from 'vue';

import ToggleButton from 'primevue/togglebutton';
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';

export default defineComponent({
  emits: ['update:donorMode', 'update:donorLock'],
  components: {
    ToggleButton,
    SelectButton,
    Divider,
  },
  props: {
    donorMode: {
      type: Number,
      default: 0,
      require: true,
    },
    donorLock: {
      type: Boolean,
      default: false,
      require: true,
    },
  },

  setup(props, ctx) {
    const donorSelectOptions = [
      { title: 'Выбрать', value: 0 },
      { title: 'Вручную', value: 1 }
    ];

    const donorSelect = computed({
      get: () => props.donorMode,
      set: val => {
        ctx.emit('update:donorMode', val);
      },
    });

    const inputDonorLock = computed({
      get: () => props.donorLock,
      set: val => {
        ctx.emit('update:donorLock', val);
      },
    });

    return {
      donorSelectOptions,
      donorSelect,
      inputDonorLock,
    };
  },
});
