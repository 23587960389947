import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  token: {
    title: 'Ключ для сброса',
    type: 'input',
  },
  password: {
    title: 'Новый пароль',
    type: 'input',
    variableType: 'password',
  },
  password2: {
    title: 'Новый пароль еще раз',
    type: 'input',
    variableType: 'password',
  },
};

export default fields;
