
import { ref, SetupContext, defineComponent, computed, PropType } from 'vue';

import Dialog from 'primevue/dialog';

import { Photo } from '@/classes/Photo';
import PhotoCatalog from '@/components/Galleria/PhotosCatalog.vue';

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    Dialog,
    PhotoCatalog,
  },
  props: {
    modelValue: Object as PropType<Photo[]>,
    id: String,
  },

  setup(props, ctx: SetupContext) {
    const selectPhotoDialog = ref(false);

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const getImageUrl = (name: string) => {
      return process.env.VUE_APP_IMAGE_URL + name;
    };

    const onDragPhotoStart = (e: DragEvent, item: Photo, index: number) => {
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('index', index.toString());
      }
    };

    const onDropPhoto = (e: DragEvent, cellId: number) => {
      if (e.dataTransfer) {
        const index = +e.dataTransfer.getData('index');
        if (inputVal.value && cellId != index) {
          const tempMap = [...inputVal.value];
          tempMap[cellId] = inputVal.value[index];
          if (cellId < index) {
            for (let i = cellId + 1; i <= index; i++) {
              tempMap[i] = inputVal.value[i - 1];
            }
          } else if(cellId > index) {
            for (let i = index; i < cellId; i++) {
              tempMap[i] = inputVal.value[i + 1];
            }
          }
          inputVal.value = tempMap;
        }
      }
    };

    const showPhotosDialogHandler = () => {
      selectPhotoDialog.value = true;
    };

    const getSelectedPhotos = () => {
      selectPhotoDialog.value = false;
    };

    const deleteHandler = (id: number) => {
      if (inputVal.value) {
        inputVal.value = inputVal.value.filter(val => val.id !== id);
      }
    };

    return {
      inputVal,
      selectPhotoDialog,
      getImageUrl,
      showPhotosDialogHandler,
      deleteHandler,
      getSelectedPhotos,
      onDragPhotoStart,
      onDropPhoto,
    };
  },
});
