
import { defineComponent, computed } from 'vue';

import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';

import PhotoSelector from '@/components/UI/Selectors/PhotoSelector.vue';
import LotSelector from '@/components/UI/Selectors/LotSelector.vue';
import LocationSelector from '@/components/UI/Selectors/LocationSelector.vue';
import NomenclatureSelector from '@/components/UI/Selectors/NomenclatureSelector.vue';
import DonorSelector from '@/components/UI/Selectors/DonorSelector.vue';
import PartsSelector from '@/components/Incomes/PartsSelector.vue';
import IncomePartsList from '@/components/Incomes/IncomePartsList.vue';

export default defineComponent({
  emits: ['update:modelValue', 'onChange'],
  components: {
    IncomePartsList,
    PhotoSelector,
    LotSelector,
    LocationSelector,
    NomenclatureSelector,
    DonorSelector,
    PartsSelector,
    InputText,
    Dropdown,
    Textarea,
    Checkbox,
    SelectButton,
    Calendar
  },
  props: {
    modelValue: {
      type: [String, Number, Object, Date],
    },
    title: { type: String, required: true },
    id: { type: String, required: true },
    type: { type: String, required: true },
    options: { default: [] },
    validationErrors: { type: Array, default: () => [] },
    invalid: { type: Boolean, default: false },
    variableType: { type: String, default: 'text' },
  },

  setup(props, ctx) {
    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        const converted = props.variableType === 'number' && val && typeof val === 'string' ? +val : val;
        ctx.emit('update:modelValue', converted);
        ctx.emit('onChange');
      },
    });

    return {
      inputVal,
    };
  },
});
