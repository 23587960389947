import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      options: _ctx.locations,
      modelValue: _ctx.inputVal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputVal = $event)),
      optionLabel: "name",
      optionValue: "id",
      showClear: true,
      class: [{ 'p-invalid': _ctx.invalid }],
      placeholder: "Выберите склад"
    }, null, 8, ["options", "modelValue", "class"])
  ]))
}