
import { ref, reactive, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FlexInput from '../UI/Input.vue';

import { Donor } from '@/classes/models/Donor';
import { Part } from '@/classes/models/Part';
import { FormHandler } from '@/classes/forms/FormHandler';
import { FormFields, FormStructure } from '@/interfaces/Input';
import { MESSAGES, PART_ERRORS } from '@/constants/errors';
import ProductFormFields from '@/constants/FormFields/part';
import { localsModule } from '@/store/modules/locals';
import { PartApi } from '@/classes/api/PartApi';
import { DonorApi } from '@/classes/api/DonorApi';

import ProductFormLayout from './ProductFormLayout.vue';
import CustomError from '@/classes/models/CustomError';

enum DONOR_MODE {
  SELECT,
  MANUAL,
}

export default defineComponent({
  name: 'ProductForm',
  emits: ['onSubmit', 'update:visible'],
  props: {
    visible: Boolean,
    id: Number,
    editMode: Boolean,
    update: Function
  },
  components: {
    ProductFormLayout,
    Dialog,
    Button,
    FlexInput,
  },
  setup(props, ctx) {
    const item = reactive<Part>(new Part({}));
    const loading = ref(false);
    const donorMode = ref<DONOR_MODE>(DONOR_MODE.SELECT);
    const donorLock = ref(true);
    const fields = reactive<FormFields>({ ...ProductFormFields });

    const formHandler = new FormHandler(fields, item);
    const formStructure: FormStructure = formHandler.createFormStructure();
    const toast = useToast();
    const api = new PartApi();
    const donorApi = new DonorApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const getDonor = async (id: number): Promise<Donor> => {
      try {
        return await donorApi.fetchById(id);
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
        return new Donor({});
      }
    };

    const savePressLocally = () => {
      localsModule.mutations.setPartsPrefs({
        donorId: item.donorId,
        donorMode: donorMode.value,
        donorLock: donorLock.value,
        marka: item.marka,
        model: item.model,
        bodyNumber: item.bodyNumber,
        engine: item.engine,
        year: item.year,
      });
    };

    const loadPrefsLocally = () => {
      item.condition = localsModule.state.parts.condition;
      donorLock.value = localsModule.state.parts.donorLock;
      if (donorLock.value) {
        donorMode.value = localsModule.state.parts.donorMode;
        item.donorId = localsModule.state.parts.donorId;
        item.marka = localsModule.state.parts.marka;
        item.model = localsModule.state.parts.model;
        item.bodyNumber = localsModule.state.parts.bodyNumber;
        item.engine = localsModule.state.parts.engine;
        item.year = localsModule.state.parts.year;
      }
    };

    const fillDonor = (donor: Donor) => {
      item.marka = donor.marka;
      item.model = donor.model;
      item.bodyNumber = donor.bodyNumber;
      item.engine = donor.engine;
      item.year = donor.year;
    };

    const setItem = (itm: Part) => {
      item.replaceBy(itm);
      formHandler.setObjectToValidate(item);
    };

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const resetFormHandler = () => {
      setItem(new Part({}));
      resetValidations();
      loadPrefsLocally();
    };

    const updateVisibleHandler = (value: boolean) => {
      ctx.emit('update:visible', value);
    };

    const fetch = async () => {
      if (props.editMode && props.id) {
        loading.value = true;
        try {
          const result = await api.fetchById(props.id);
          setItem(result);
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          }
        }
        loading.value = false;
      } else {
        resetFormHandler();
        loadPrefsLocally();
      }
    };

    const submitHandler = async (exit = true) => {
      if (donorMode.value === DONOR_MODE.MANUAL) {
        item.donorId = undefined;
      }
      if (donorMode.value === DONOR_MODE.SELECT && !item.donorId) {
        toast.add({
          severity: 'warn',
          detail: PART_ERRORS.noDonor,
          life: 3000,
        });
        return;
      } else {
        if (item.donorId) {
          const donor = await getDonor(item.donorId);
          fillDonor(donor);
        }
      }

      const valid = await formHandler.checkValidations();
      if (valid) {
        loading.value = true;
        try {
          let result: Part;
          if (!props.editMode) {
            result = await api.send(item);
          } else {
            result = await api.update(item);
          }
          ctx.emit('onSubmit', result);
          if (props.update) {
            props.update();
          }

          savePressLocally();
          resetFormHandler();
          if (exit) {
            inputVisible.value = false;
          }
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        toast.add({
          severity: 'warn',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    };

    return {
      donorMode,
      donorLock,
      formStructure,
      fields,
      item,
      formHandler,
      loading,
      fetch,
      submitHandler,
      resetFormHandler,
      updateVisibleHandler,
    };
  },
});
