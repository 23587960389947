import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  price: {
    title: 'Цена',
    type: 'input',
  },
  discount: {
    title: 'Скидка',
    type: 'input',
  },
  priceAdd: {
    title: 'Добавить в цену (%)',
    type: 'input',
  },
};

export default fields;
