
import { ref, reactive, defineComponent, computed, PropType } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

// import { Donor } from '@/classes/models/Donor';
import { FormHandler } from '@/classes/forms/FormHandler';
import { FormFields, FormStructure } from '@/interfaces/Input';
import { MESSAGES } from '@/constants/errors';
import ProductFormFields from '@/constants/FormFields/partMulti';
import { PartApi } from '@/classes/api/PartApi';
// import { DonorApi } from '@/classes/api/DonorApi';

import CustomError from '@/classes/models/CustomError';
import { Part } from '@/classes/models/Part';

enum DONOR_MODE {
  SELECT,
  MANUAL,
}

interface UpdateMany {
  ids: number[];
  donorId?: number;
  price?: number;
  discount?: number;
  priceAdd?: number;
}

export default defineComponent({
  name: 'ProductMultiForm',
  emits: ['onSubmit', 'update:visible'],
  props: {
    visible: Boolean,
    id: {
      type: Array as PropType<Array<Part>>,
      required: true,
    },
    update: Function,
  },
  components: {
    Dialog,
    Button,
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.FlexInput = require('@/components/UI/Input').default;
    }
  },
  setup(props, ctx) {
    const item = reactive<UpdateMany>({
      ids: [],
      discount: 0,
      price: 0,
    });
    const loading = ref(false);
    const donorMode = ref<DONOR_MODE>(DONOR_MODE.SELECT);
    const donorLock = ref(true);
    const fields = reactive<FormFields>({ ...ProductFormFields });

    const formHandler = new FormHandler(fields, item);
    const formStructure: FormStructure = formHandler.createFormStructure();
    const toast = useToast();
    const api = new PartApi();
    // const donorApi = new DonorApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    // const getDonor = async (id: number): Promise<Donor> => {
    //   try {
    //     return await donorApi.fetchById(id);
    //   } catch (error) {
    //     if (error instanceof CustomError) {
    //       error.show('error', 5000, toast);
    //     } else {
    //       console.log(error);
    //     }
    //     return new Donor({});
    //   }
    // };
    //
    // const fillDonor = (donor: Donor) => {
    //   console.log(donor);
    // };

    const updateVisibleHandler = (value: boolean) => {
      ctx.emit('update:visible', value);
    };

    const submitHandler = async () => {
      // if (donorMode.value === DONOR_MODE.MANUAL) {
      //   item.donorId = undefined;
      // }
      // if (donorMode.value === DONOR_MODE.SELECT && !item.donorId) {
      //   toast.add({
      //     severity: 'warn',
      //     detail: PART_ERRORS.noDonor,
      //     life: 3000,
      //   });
      //   return;
      // } else {
      //   if (item.donorId) {
      //     const donor = await getDonor(item.donorId);
      //     fillDonor(donor);
      //   }
      // }

      const valid = await formHandler.checkValidations();
      if (valid) {
        loading.value = true;
        try {
          const ids: number[] = [];
          props.id.forEach(item => {
            if (item.id) {
              ids.push(item.id);
            }
          });

          if (ids.length) {
            await api.updateMany({
              ids,
              discount: item.discount ? +item.discount : null,
              priceAdd: item.priceAdd ? +item.priceAdd : null,
            });
          }

          if (props.update) {
            props.update();
          }

          inputVisible.value = false;
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        toast.add({
          severity: 'warn',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    };

    return {
      donorMode,
      donorLock,
      formStructure,
      fields,
      item,
      formHandler,
      loading,
      fetch,
      submitHandler,
      updateVisibleHandler,
    };
  },
});
