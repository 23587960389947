import axios from '@/axios-zaconte';
import CustomError from './models/CustomError';

export class Captcha {
  image = '';
  guid = '';

  async fetch() {
    try {
      const result = await axios.get('/captcha');
      this.image = `data:image/png;base64,${result.data.image}`;
      this.guid = result.data.guid;
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
