
import { ref, reactive, onMounted, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Skeleton from 'primevue/skeleton';

import { Captcha as CaptchaClass } from '@/classes/Captcha';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    Skeleton
  },

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  setup(props, ctx) {
    let captcha = reactive<CaptchaClass>(new CaptchaClass());
    const loading = ref<boolean>(true);
    const captchaRef = ref<HTMLImageElement | null>(null);
    const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      }
    });

    const update = async () => {
      captcha = new CaptchaClass();
      captchaRef.value && (captchaRef.value.src = '');
      loading.value = true;
      try {
        await captcha.fetch();
        captchaRef.value && (captchaRef.value.src = captcha.image);
        inputVal.value = captcha.guid;
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    onMounted(() => {
      update();
    });

    return {
      captchaRef,
      loading,
      update
    };
  }
});
